@import '../../scss/variables.scss';


.wed-center{
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mb-50{
    margin-bottom: 50px;
}

.wedding-series {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 12px;
    row-gap: 12px;
    width: 100%;

    // .wedding-series__box
    &__box {
        flex: 1 1 31%;
        min-width: 320px;
        position: relative;
        overflow: hidden;
        
        &:hover::before{
            background-color: rgba(0, 0, 0, 0.301);
        }
        &:hover img{
            transform: scale(1.1);
        }
        &:hover .wedding-series__title{
            transform: scale(1.1);
        }

        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.137);
            transition: background-color 0.8s ease;
        } 
    }

    &__item{
        padding-bottom: 45%;
    }

    & img{
        position: absolute;
        top: -30px;
        left: 0;
        z-index: 1;
        object-fit: cover;
        width: 100%;
        height: auto;
        transition: all 0.8s ease;
    }

    // .wedding-series__title
    &__title {
        position: absolute;
        bottom: 50px;
        right: 30px;
        z-index: 3;
        font-family: 'Mont';
        font-size: 25px;
        color: #fff;
        transition: all 0.8s ease;

    }

    // .wedding-series__sub-title
    &__sub-title {
        position: absolute;
        bottom: 20px;
        right: 30px;
        z-index: 3;
        font-family: 'Mont';
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        text-transform: uppercase;
    }
}